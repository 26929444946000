import {
    Conversation,
    ConversationsState,
    Message,
    MessagesState,
    User
} from '../types'
import { API_BASE_URL } from '../constants'
import { SSE } from 'sse.js'
import { sys } from 'typescript'

/**
 * Sends a message to the server and updates the messages state with the response.
 * @param {Object} messages The current state of messages.
 * @param {Function} setAllMessages The setState function for updating messages.
 * @param {string} newMessageText The text of the new message.
 * @param {Object} botMessageData The message object for the chatbot.
 * @param {Function} setWaitingForResponse The setState function for waiting for a response.
 * @param {Object} selectedConversation The currently selected conversation.
 * @param {Function} setSelectedConversation The setState function for updating the selected conversation.
 * @param {Object} conversations The current state of conversations.
 * @param {Function} setConversations The setState function for updating conversations.
 * @param {Object} systemPrompt The system prompt for the chatbot.
 * @returns {Promise} A promise that resolves when the message is sent.
 * @throws {Error} An error if the EventSource fails.
 * @async
 */
export const sendMessage = async (
    messages: MessagesState,
    newMessageText: string,
    botMessageData: Message,
    setMessages: React.Dispatch<React.SetStateAction<MessagesState>>,
    setWaitingForResponse: React.Dispatch<React.SetStateAction<boolean>>,
    selectedConversation: Conversation,
    setSelectedConversation: React.Dispatch<
        React.SetStateAction<Conversation | null>
    >,
    conversations: ConversationsState,
    setConversations: React.Dispatch<React.SetStateAction<any>>,
    systemPrompt: { id: number; prompt: string },
    user: User
) => {
    const requestBody = createRequestBody(
        messages.messages.filter((msg) => msg.id !== -2),
        newMessageText,
        messages.messages[0].conversationID,
        messages.messages[0].userID,
        systemPrompt['prompt'],
        systemPrompt['id'],
    )

    // Create a new EventSource object to stream the response from the server
    const eventSource = new SSE(`${API_BASE_URL}/chat`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.accessToken
        },
        payload: JSON.stringify(requestBody),
    })
    let sources: any = null
    let messageText = '' // The text of the message received from the server

    // Event listener for the message event
    eventSource.onmessage = function (event) {
        try {
            let data = JSON.parse(event.data)
            // Check if the data contains sources
            if ('sources' in data) {
                sources = data['sources']
            }
            // Check if the data contains text
            else if ('text' in data) {
                messageText += data['text']
                updateMessagesFromEvent(
                    botMessageData,
                    messageText,
                    messages,
                    setMessages
                )
            }
            // Check if the data contains user and chatbot message data
            else if (
                'userMessageData' in data &&
                'chatbotMessageData' in data
            ) {
                // Create message objects from the data
                let userMessageData: Message = {
                    id: data['userMessageData'].id,
                    userID: data['userMessageData'].user_id,
                    createdAt: new Date(data['userMessageData'].created_at),
                    text: data['userMessageData'].text,
                    role: data['userMessageData'].role,
                    editIndex: data['userMessageData'].edit_index,
                    editAmount: data['userMessageData'].edit_amount,
                    conversationID: data['userMessageData'].conversation_id,
                    previousMessageID:
                        data['userMessageData'].previous_message_id,
                    nextMessageID: data['userMessageData'].next_message_id,
                    previousEditID: data['userMessageData'].previous_edit_id,
                    nextEditID: data['userMessageData'].next_edit_id,
                    sources: data['userMessageData'].sources,
                    systemPromptID: data['userMessageData'].system_prompt_id,
                }
                botMessageData = {
                    id: data['chatbotMessageData'].id,
                    userID: data['chatbotMessageData'].user_id,
                    createdAt: new Date(data['chatbotMessageData'].created_at),
                    text: data['chatbotMessageData'].text,
                    role: data['chatbotMessageData'].role,
                    editIndex: data['chatbotMessageData'].edit_index,
                    editAmount: data['chatbotMessageData'].edit_amount,
                    conversationID: data['chatbotMessageData'].conversation_id,
                    previousMessageID:
                        data['chatbotMessageData'].previous_message_id,
                    nextMessageID: data['chatbotMessageData'].next_message_id,
                    previousEditID: data['chatbotMessageData'].previous_edit_id,
                    nextEditID: data['chatbotMessageData'].next_edit_id,
                    sources: data['chatbotMessageData'].sources,
                    systemPromptID: data['userMessageData'].system_prompt_id,
                }
                eventSource.close()
                const newMessages = [
                    ...messages.messages.filter(
                        (msg) => msg.id !== -2 && msg.id !== -1
                    ),
                    userMessageData,
                    botMessageData,
                ]
                setMessages({ messages: newMessages })
                selectedConversation.lastViewedMessageId = botMessageData.id
                setSelectedConversation({ ...selectedConversation })
                let newConversations = { ...conversations }
                newConversations.conversations =
                    newConversations.conversations.filter(
                        (conversation) =>
                            conversation.id !== selectedConversation.id
                    )
                newConversations.conversations.unshift(selectedConversation)
                setConversations(newConversations)
                setWaitingForResponse(false)
            }
        } catch (e) {
            console.error(e)
            setWaitingForResponse(false)
        }
    }

    eventSource.onerror = function (error) {
        console.error('EventSource failed:', error)
        setWaitingForResponse(false)
        eventSource.close()
        throw new Error('EventSource failed')
    }
}

export const editMessage = async (
    messages: MessagesState,
    newMessageText: string,
    botMessageData: Message,
    setMessages: React.Dispatch<React.SetStateAction<MessagesState>>,
    setWaitingForResponse: React.Dispatch<React.SetStateAction<boolean>>,
    selectedConversation: Conversation,
    setSelectedConversation: React.Dispatch<
        React.SetStateAction<Conversation | null>
    >,
    conversations: ConversationsState,
    setConversations: React.Dispatch<React.SetStateAction<any>>,
    setEditingMessageId: React.Dispatch<React.SetStateAction<number | null>>,
    previousEditId: number,
    systemPrompt: { id: number; prompt: string },
    user: User
) => {
    const requestBody = createRequestBody(
        messages.messages.filter((msg) => msg.id !== -2),
        newMessageText,
        messages.messages[0].conversationID,
        messages.messages[0].userID,
        systemPrompt['prompt'],
        systemPrompt['id'],
        previousEditId
    )
    const eventSource = new SSE(`${API_BASE_URL}/chat/edit`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.accessToken
        },
        payload: JSON.stringify(requestBody),
    })
    let sources: any = null
    let messageText = ''
    eventSource.onmessage = function (event) {
        try {
            let data = JSON.parse(event.data)
            if ('sources' in data) {
                sources = data['sources']
            } else if ('text' in data) {
                messageText += data['text']
                updateMessagesFromEvent(
                    botMessageData,
                    messageText,
                    messages,
                    setMessages
                )
            } else if (
                'userMessageData' in data &&
                'chatbotMessageData' in data
            ) {
                let userMessageData: Message = {
                    id: data['userMessageData'].id,
                    userID: data['userMessageData'].user_id,
                    createdAt: new Date(data['userMessageData'].created_at),
                    text: data['userMessageData'].text,
                    role: data['userMessageData'].role,
                    editIndex: data['userMessageData'].edit_index,
                    editAmount: data['userMessageData'].edit_amount,
                    conversationID: data['userMessageData'].conversation_id,
                    previousMessageID:
                        data['userMessageData'].previous_message_id,
                    nextMessageID: data['userMessageData'].next_message_id,
                    previousEditID: data['userMessageData'].previous_edit_id,
                    nextEditID: data['userMessageData'].next_edit_id,
                    sources: data['userMessageData'].sources,
                    systemPromptID: data['userMessageData'].system_prompt_id,
                }
                botMessageData = {
                    id: data['chatbotMessageData'].id,
                    userID: data['chatbotMessageData'].user_id,
                    createdAt: new Date(data['chatbotMessageData'].created_at),
                    text: data['chatbotMessageData'].text,
                    role: data['chatbotMessageData'].role,
                    editIndex: data['chatbotMessageData'].edit_index,
                    editAmount: data['chatbotMessageData'].edit_amount,
                    conversationID: data['chatbotMessageData'].conversation_id,
                    previousMessageID:
                        data['chatbotMessageData'].previous_message_id,
                    nextMessageID: data['chatbotMessageData'].next_message_id,
                    previousEditID: data['chatbotMessageData'].previous_edit_id,
                    nextEditID: data['chatbotMessageData'].next_edit_id,
                    sources: data['chatbotMessageData'].sources,
                    systemPromptID: data['userMessageData'].system_prompt_id,
                }

                eventSource.close()
                const newMessages = [
                    ...messages.messages.filter(
                        (msg) => msg.id !== -2 && msg.id !== -1
                    ),
                    userMessageData,
                    botMessageData,
                ]
                setMessages({ messages: newMessages })
                console.log(newMessages)
                selectedConversation.lastViewedMessageId = botMessageData.id
                setSelectedConversation({ ...selectedConversation })
                let newConversations = { ...conversations }
                newConversations.conversations =
                    newConversations.conversations.filter(
                        (conversation) =>
                            conversation.id !== selectedConversation.id
                    )
                newConversations.conversations.unshift(selectedConversation)
                setConversations(newConversations)
                setWaitingForResponse(false)
                setEditingMessageId(null)
            }
        } catch (e) {
            console.error(e)
            setWaitingForResponse(false)
        }
    }

    eventSource.onerror = function (error) {
        console.error('EventSource failed:', error)
        setWaitingForResponse(false)
        eventSource.close()
        throw new Error('EventSource failed')
    }
}

/**
 * Creates the request body for the POST request from the current messages.
 * @param {Array} conversationMessages Array of messages for the selected conversation.
 * @returns {Object} The request body object.
 */
function createRequestBody(
    conversationMessages: Message[],
    newMessageText: string,
    conversationID: number,
    userID: string,
    systemPrompt: string,
    systemPromptID: number,
    previousEditId?: number
) {
    return {
        messages: conversationMessages.map((msg) => ({
            role: msg.role,
            content: msg.text,
        })),
        text: newMessageText,
        conversation_id: conversationID,
        user_id: userID,
        previous_edit_id: previousEditId,
        system_prompt: systemPrompt,
        system_prompt_id: systemPromptID,
    }
}

/**
 
/**
 * Updates the messages state based on the event data received.
 */
function updateMessagesFromEvent(
    botMessageData: Message,
    text: string,
    messages: MessagesState,
    setMessages: React.Dispatch<React.SetStateAction<MessagesState>>
) {
    botMessageData.text = text
    const newMessages = [
        ...messages.messages.filter((msg) => msg.id !== botMessageData.id),
        botMessageData,
    ]
    setMessages({ messages: newMessages })
}
