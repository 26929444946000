import { useAuth0 } from "@auth0/auth0-react";
import { API_BASE_URL } from "../constants"
import { create } from "domain";
export async function getUserData(token: string, email : string, sub : string){

    const userResponse = await fetch(`${API_BASE_URL}/user/me`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    })
    if (!userResponse.ok) {
        console.log('User fetch failed, trying to create user')
        const createUserResponse = await fetch(`${API_BASE_URL}/user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                sub: sub,
                email: email
            }),
        })
        if (!createUserResponse.ok){
            console.error("Failed to create new user")
            return
        }
        return await createUserResponse.json()
    }
    const userJson = await userResponse.json()
    return userJson
}

