import { sendMessage } from '../api/chatAPI'
import { editMessage } from '../api/chatAPI'
import {
    MessagesState,
    ConversationsState,
    Message,
    Conversation,
    User
} from '../types'
import { useState } from 'react'
import { API_BASE_URL } from '../constants'
import { useUser } from '../context/UserContext'
/**
 * Custom hook for handling message operations in the chat app
 *
 * @param {Conversation} selectedConversation The ID of the currently selected conversation.
 * @param {Object} messages The current state of all messages, keyed by conversation ID.
 * @param {Function} setMessages State setter function for updating messages.
 * @param {Function} setSelectedConversation State setter function for updating the selected conversation.
 * @param {Object} conversations The current state of all conversations.
 * @param {Function} setConversations State setter function for updating conversations.
 * @param {number} userId The ID of the currently logged-in user.
 * @param {Object} systemPrompt The system prompt for the current user.
 * @returns {Object} An object containing functions and state variables for message operations.
 * @returns {boolean} waitingForResponse A boolean indicating whether the app is waiting for a response from the server.
 * @returns {Function} handleSendMessage A function to send a new message in the selected conversation.
 * @returns {Function} onEditMessage A function to edit an existing message in the selected conversation.
 * @returns {number | null} editingMessageId The ID of the message currently being edited.
 * @returns {Function} setEditingMessageId A function to set the ID of the message being edited.
 * @returns {Function} onSwitchEdit A function to switch between different versions of an edited message.
 */
function useMessages(
    selectedConversation: Conversation | null,
    setSelectedConversation: React.Dispatch<
        React.SetStateAction<Conversation | null>
    >,
    conversations: ConversationsState,
    messages: MessagesState,
    setMessages: React.Dispatch<React.SetStateAction<MessagesState>>,
    setConversations: React.Dispatch<React.SetStateAction<ConversationsState>>,
    systemPrompt: { id: number; prompt: string }
) {
    const [waitingForResponse, setWaitingForResponse] = useState<boolean>(false)
    const [editingMessageId, setEditingMessageId] = useState<number | null>(
        null
    )
    const { user } = useUser()


    /**
     * Sends a new message in the currently selected conversation.
     * This function first checks if there's a selected conversation and if the system is already waiting for a response.
     * If not, it proceeds to simulate sending a message by updating the state with a new user message followed by a placeholder for the bot's response.
     * Actual sending to the backend and error handling are simulated.
     *
     * @param {string} newMessageText The text content of the new message.
     */
    const handleSendMessage = async (newMessageText: string) => {
        if (!user) { throw new Error('User not found') }
        // Prevent message sending if no conversation is selected or if already waiting for another response
        if (!selectedConversation || waitingForResponse) return

        // Store the original state in case of an error to revert back
        const originalMessages: MessagesState = JSON.parse(
            JSON.stringify(messages)
        )
        const originalConversations: ConversationsState = JSON.parse(
            JSON.stringify(conversations)
        )
        const originalSelectedConversation: Conversation | null = JSON.parse(
            JSON.stringify(selectedConversation)
        )


        // IDs for the user and bot messages to potentially delete them in case of errors
        let delete_user_msg_id = null
        let delete_bot_msg_id = null

        try {
            // Indicate that a response is being awaited
            setWaitingForResponse(true)

            // Create a new message object for the user
            let newMessages = { ...messages }
            newMessages.messages.push({
                id: -1, // Temporary ID until confirmed by the server
                userID: user.sub,
                text: newMessageText,
                role: 'user',
                conversationID: selectedConversation.id,
                createdAt: new Date(),
                nextMessageID: null,
                previousMessageID: null,
                editIndex: 0,
                previousEditID: null,
                nextEditID: null,
                editAmount: 1,
                sources: {},
                systemPromptID: null,
            })

            // Create a placeholder bot message
            let botMessageData = {
                id: -2, // Temporary ID until confirmed by the server
                userID: user.sub,
                text: '',
                role: 'assistant',
                conversationID: selectedConversation.id,
                createdAt: new Date(),
                nextMessageID: null,
                previousMessageID: null,
                editIndex: 0,
                previousEditID: null,
                nextEditID: null,
                editAmount: 1,
                sources: {},
                systemPromptID: null,
            }

            // Add the bot message to the conversation
            newMessages.messages.push(botMessageData)
            setMessages(newMessages)

            // Simulate sending the message and getting a response from the server
            await sendMessage(
                newMessages,
                newMessageText,
                botMessageData,
                setMessages,
                setWaitingForResponse,
                selectedConversation,
                setSelectedConversation,
                conversations,
                setConversations,
                systemPrompt,
                user
            )
        } catch (error) {
            // Log and handle errors by resetting to original states
            console.error('Error sending message:', error)
            setMessages(originalMessages)
            setConversations(originalConversations)
            setSelectedConversation(originalSelectedConversation)
            setWaitingForResponse(false)

            // Attempt to delete any temporary messages created in this session
            if (delete_user_msg_id) {
                await fetch(
                    `${API_BASE_URL}/message/catch_${delete_user_msg_id}`,
                    {
                        method: 'DELETE',
                        headers: {
                            'Authorization': 'Bearer ' + user.accessToken,
                        }
                    }
                )
            }
            if (delete_bot_msg_id) {
                await fetch(
                    `${API_BASE_URL}/message/catch_${delete_bot_msg_id}`,
                    {
                        method: 'DELETE',
                        headers: {
                            'Authorization': 'Bearer ' + user.accessToken,
                        }
                    }
                )
            }
        }
    }

    /**
     * Edits an existing message by appending a new version of the text, effectively creating a new edit
     * entry for the message. This is done by fetching the latest state of the conversation's messages,
     * updating the local state, and attempting to post the new message version to the server.
     *
     * @param {number} messageId The ID of the message to edit.
     * @param {string} newMessageText The updated text for the message.
     */
    const onEditMessage = async (messageId: number, newMessageText: string) => {
        if (!user) { throw new Error('User not found') }
        // Guard clause to ensure there is a selected conversation and new message text
        if (!selectedConversation || !newMessageText.trim()) return

        // Save the original state in case of an error to revert changes
        const originalMessages: MessagesState = JSON.parse(
            JSON.stringify(messages)
        )
        const originalConversations: ConversationsState = JSON.parse(
            JSON.stringify(conversations)
        )
        const originalSelectedConversation: Conversation | null = JSON.parse(
            JSON.stringify(selectedConversation)
        )

        // Placeholder variables for potentially created message IDs to clean up in case of an error
        let delete_user_msg_id = null
        let delete_bot_msg_id = null

        try {
            // Indicate that the app is waiting for a response
            setWaitingForResponse(true)

            // Find the index of the message being edited
            const editedMessageIndex = messages.messages.findIndex(
                (msg) => msg.id === messageId
            )

            // Slice messages up to the edited one (non-inclusive)
            messages.messages = messages.messages.slice(0, editedMessageIndex)

            // Fetch all conversation messages from the server to ensure sync with the latest state
            const allConversationMessagesJson = await fetch(
                `${API_BASE_URL}/message/conversation_${selectedConversation.id}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + user.accessToken,
                    }
                }
            ).then((response) => response.json())

            // Construct a map of messages by their IDs
            let messageMap: { [key: string]: Message } = {}
            allConversationMessagesJson.forEach((m: any) => {
                let message: Message = {
                    id: m.id,
                    userID: m.userID,
                    text: m.text,
                    role: m.role,
                    conversationID: m.conversation_id,
                    createdAt: new Date(m.created_at),
                    nextMessageID: m.next_message_id,
                    previousMessageID: m.previous_message_id,
                    editIndex: m.edit_index,
                    previousEditID: m.previous_edit_id,
                    nextEditID: m.next_edit_id,
                    editAmount: m.edit_amount,
                    sources: m.sources,
                    systemPromptID: m.system_prompt_id,
                }
                messageMap[message.id] = message
            })

            // Determine the last message in the edit chain
            let lastMessageEdit: Message = messageMap[messageId]
            while (lastMessageEdit.nextEditID) {
                lastMessageEdit = messageMap[lastMessageEdit.nextEditID]
            }

            // Calculate the new edit index
            const newEditAmount = lastMessageEdit.editAmount + 1
            let newMessages = [...messages.messages]

            // Append the new user message
            newMessages.push({
                id: -1, // Temporary ID
                userID: user.sub,
                text: newMessageText,
                role: 'user',
                conversationID: selectedConversation.id,
                createdAt: new Date(),
                nextMessageID: null,
                previousMessageID: lastMessageEdit.previousMessageID,
                editIndex: newEditAmount - 1,
                previousEditID: lastMessageEdit.id,
                nextEditID: null,
                editAmount: newEditAmount,
                sources: {},
                systemPromptID: null,
            })

            // Create a placeholder for bot response
            let botMessageData = {
                id: -2, // Temporary ID
                userID: user.sub,
                text: '',
                role: 'assistant',
                conversationID: selectedConversation.id,
                createdAt: new Date(),
                nextMessageID: null,
                previousMessageID: newMessages[newMessages.length - 1].id,
                editIndex: 0,
                previousEditID: null,
                nextEditID: null,
                editAmount: 1,
                sources: {},
                systemPromptID: null,
            }

            // Append the bot message
            newMessages.push(botMessageData)
            setMessages({ messages: newMessages })

            // Attempt to update the message on the server
            await editMessage(
                { messages: newMessages },
                newMessageText,
                botMessageData,
                setMessages,
                setWaitingForResponse,
                selectedConversation,
                setSelectedConversation,
                conversations,
                setConversations,
                setEditingMessageId,
                lastMessageEdit.id,
                systemPrompt,
                user
            )
        } catch (error) {
            // Handle errors by logging and reverting to original state
            console.error('Error editing message:', error)
            setMessages(originalMessages)
            setConversations(originalConversations)
            setSelectedConversation(originalSelectedConversation)
            setEditingMessageId(null)
            setWaitingForResponse(false)

            // Attempt to delete any temporary messages that might have been created
            if (delete_user_msg_id) {
                await fetch(
                    `${API_BASE_URL}/message/catch_${delete_user_msg_id}`,
                    {
                        method: 'DELETE',
                        headers: {
                            'Authorization': 'Bearer ' + user.accessToken,
                        }
                    }
                )
            }
            if (delete_bot_msg_id) {
                await fetch(
                    `${API_BASE_URL}/message/catch_${delete_bot_msg_id}`,
                    {
                        method: 'DELETE',
                        headers: {
                            'Authorization': 'Bearer ' + user.accessToken,
                        }
                    }
                )
            }
        }
    }

    /**
     * Switches between different edit versions of a message within a conversation.
     *
     * @param {number} messageId The ID of the current message being viewed.
     * @param {boolean} back A flag to determine the direction to switch: `true` for previous, `false` for next.
     */
    const onSwitchEdit = async (messageId: number, back: boolean) => {
        if (!user) { throw new Error('User not found') }
        // Ensure there is a selected conversation
        if (selectedConversation === null) return

        // Save the original state in case we need to revert due to an error
        const originalMessages: MessagesState = JSON.parse(
            JSON.stringify(messages)
        )
        const originalConversations: ConversationsState = JSON.parse(
            JSON.stringify(conversations)
        )
        const originalSelectedConversation: Conversation | null = JSON.parse(
            JSON.stringify(selectedConversation)
        )

        // Find the index of the message currently being edited
        const editedMessageIndex = messages.messages.findIndex(
            (msg) => msg.id === messageId
        )

        // Remove all messages after the current one to prepare for new edits to be displayed
        messages.messages = messages.messages.slice(0, editedMessageIndex)

        // Fetch all messages of the current conversation to ensure synchronization with the server
        const allConversationMessagesJson = await fetch(
            `${API_BASE_URL}/message/conversation_${selectedConversation.id}`,
            {
                headers: {
                    'Authorization': 'Bearer ' + user.accessToken,
                }
            }
        ).then((response) => response.json())

        // Create a map of all messages by their IDs for quick access
        let messageMap: { [key: string]: Message } = {}
        allConversationMessagesJson.forEach((m: any) => {
            let message: Message = {
                id: m.id,
                userID: m.userID,
                text: m.text,
                role: m.role,
                conversationID: m.conversation_id,
                createdAt: new Date(m.created_at),
                nextMessageID: m.next_message_id,
                previousMessageID: m.previous_message_id,
                editIndex: m.edit_index,
                previousEditID: m.previous_edit_id,
                nextEditID: m.next_edit_id,
                editAmount: m.edit_amount,
                sources: {},
                systemPromptID: m.system_prompt_id,
            }
            messageMap[message.id] = message
        })

        // Determine the current message based on the provided messageId
        let currMessage: Message = messageMap[messageId]

        // Add the current message to the list of messages to display
        if (back && currMessage.previousEditID) {
            currMessage = messageMap[currMessage.previousEditID] // Move to the previous edit
            messages.messages.push(currMessage)
        } else if (!back && currMessage.nextEditID) {
            currMessage = messageMap[currMessage.nextEditID] // Move to the next edit
            messages.messages.push(currMessage)
        }

        // Update messages to include all following the current message
        while (currMessage.nextMessageID) {
            currMessage = messageMap[currMessage.nextMessageID]
            messages.messages.push(currMessage)
        }

        // Update the messages state with the new list
        setMessages({ ...messages })

        // Update the last viewed message ID in the selected conversation
        selectedConversation.lastViewedMessageId = currMessage.id
        setSelectedConversation({ ...selectedConversation })

        // Update the conversations array with the new state of the selected conversation
        conversations.conversations = conversations.conversations.map(
            (conversation) => {
                if (conversation.id === selectedConversation.id) {
                    return selectedConversation
                }
                return conversation
            }
        )
        setConversations({ ...conversations })

        try {
            // Attempt to update the last viewed message ID on the server
            await fetch(`${API_BASE_URL}/conversation/last_message`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.accessToken,
                },
                body: JSON.stringify({
                    last_viewed_message_id: currMessage.id,
                    id: selectedConversation.id,
                }),
            })
        } catch (error) {
            // Handle errors by reverting to the original state and logging the error
            console.error('Error updating conversation:', error)
            setMessages(originalMessages)
            setConversations(originalConversations)
            setSelectedConversation(originalSelectedConversation)

            // Attempt to reset the last viewed message ID on the server to its original state
            await fetch(`${API_BASE_URL}/conversation/last_message`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.accessToken,
                },
                body: JSON.stringify({
                    last_viewed_message_id:
                        originalSelectedConversation?.lastViewedMessageId,
                    id: originalSelectedConversation?.id,
                }),
            })
        }
    }
    return {
        waitingForResponse,
        handleSendMessage,
        onEditMessage,
        editingMessageId,
        setEditingMessageId,
        onSwitchEdit,
    }
}

export default useMessages
