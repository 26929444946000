import { useState } from 'react'
import {
    ConversationsState,
    MessagesState,
    Conversation,
    Message,
} from '../types'
import { API_BASE_URL } from '../constants'
import { useUser } from '../context/UserContext'
/**
 * Custom hook for managing conversations, including selecting, creating,
 * renaming, and deleting conversation, as well as managing the deletion modal state.
 *
 * @param {Array} conversations Array of conversation objects.
 * @param {Function} setConversations Setter function for updating the conversations array.
 * @param {Function} setMessages Setter function for updating allMessages.
 * @param {Function} setSelectedConversation Setter function for updating the selectedConversation.
 * @param {Object} selectedConversation The currently selected conversation object.
 * @param {Function} userId The ID of the currently logged-in user.
 * @returns {Object} Object containing functions and state variables for managing conversations.
 * @returns {boolean} isDeleteModalOpen State variable indicating whether the delete confirmation modal is open.
 * @returns {number} conversationToDelete ID of the conversation to delete.
 * @returns {number} editingConversationId ID of the conversation being edited.
 * @returns {Function} onCreateNewConversation Function to create a new conversation.
 * @returns {Function} onRenameConversation Function to rename an existing conversation.
 * @returns {Function} onDeleteConversation Function to delete a conversation.
 * @returns {Function} handleCloseModal Function to close the delete confirmation modal.
 * @returns {Function} handleConfirmDelete Function to confirm the deletion of a conversation.
 * @returns {Function} setEditingConversationId Function to set the ID of the conversation being edited.
 */
function useConversations(
    conversations: ConversationsState,
    setConversations: React.Dispatch<React.SetStateAction<ConversationsState>>,
    setMessages: React.Dispatch<React.SetStateAction<MessagesState>>,
    messages: MessagesState,
    selectedConversation: Conversation | null,
    setSelectedConversation: React.Dispatch<
        React.SetStateAction<Conversation | null>
    >,
) {
    // State variables for managing the delete confirmation modal and the conversation to delete
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false) // State variable to manage the visibility of the delete confirmation modal
    const [conversationToDelete, setConversationToDelete] = useState<
        number | null
    >(null) // State variable to store the ID of the conversation to delete
    const [editingConversationId, setEditingConversationId] = useState<
        number | null
    >(null) // State variable to store the ID of the conversation being edited
    const { user } = useUser()
    /* Function to create a new conversation */
    const onCreateNewConversation = async () => {
        if (user === null) return
        // Create a copy of the current states to revert to in case of an error
        const originalMessages: MessagesState = JSON.parse(
            JSON.stringify(messages ?? {})
        )
        const originalConversations: ConversationsState = JSON.parse(
            JSON.stringify(conversations ?? {})
        )
        const originalSelectedConversation: Conversation | null = JSON.parse(
            JSON.stringify(selectedConversation ?? {})
        )
        let newConversationId = null
        try {
            // API call to create a new conversation
            const conversationResponse = await fetch(
                `${API_BASE_URL}/conversation`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + user.accessToken
                    },
                    body: JSON.stringify({
                        name: 'New Chat',
                        user_id: user.sub,

                    }),
                }
            )
            // Parse the response JSON and create a new conversation object
            const newConversationDataJson = await conversationResponse.json()
            const newConversationData: Conversation = {
                id: newConversationDataJson.conversation.id,
                userId: newConversationDataJson.conversation.user_id,
                name: newConversationDataJson.conversation.name,
                createdAt: new Date(
                    newConversationDataJson.conversation.created_at
                ),
                updatedAt: new Date(
                    newConversationDataJson.conversation.updated_at
                ),
                lastViewedMessageId:
                    newConversationDataJson.conversation.last_viewed_message_id,
            }
            newConversationId = newConversationData.id
            let newConversations = { ...conversations }

            // Add the new conversation to the conversations array
            const newMessageData: Message = {
                id: newConversationDataJson.message.id,
                userID: newConversationDataJson.message.user_id,
                text: newConversationDataJson.message.text,
                role: newConversationDataJson.message.role,
                conversationID: newConversationDataJson.message.conversation_id,
                createdAt: new Date(newConversationDataJson.message.created_at),
                nextMessageID: newConversationDataJson.message.next_message_id,
                previousMessageID:
                    newConversationDataJson.message.previous_message_id,
                editIndex: newConversationDataJson.message.edit_index,
                previousEditID:
                    newConversationDataJson.message.previous_edit_id,
                nextEditID: newConversationDataJson.message.next_edit_id,
                editAmount: newConversationDataJson.message.edit_amount,
                sources: newConversationDataJson.message.sources,
                systemPromptID:
                    newConversationDataJson.message.system_prompt_id,
            }
            newConversationData.lastViewedMessageId = newMessageData.id
            newConversations.conversations = [
                newConversationData,
                ...newConversations.conversations,
            ]
            setConversations(newConversations)
            // setEditingConversationId(newConversationData.id)
            setMessages({ messages: [newMessageData] })
            setSelectedConversation(newConversationData)
        } catch (error) {
            console.error('Error creating new conversation:', error)
            setConversations(originalConversations)
            setMessages(originalMessages)
            setSelectedConversation(originalSelectedConversation)
            setEditingConversationId(null)
            if (newConversationId !== null) {
                try {
                    await fetch(
                        `${API_BASE_URL}/conversation/catch_${newConversationId}`,
                        {
                            method: 'DELETE',
                            headers: {
                                'Authorization': 'Bearer ' + user.accessToken
                            },
                        },

                    )
                } catch (error) {
                    console.error('Error deleting new conversation:', error)
                }
            }
        }
    }

    /**
     * Renames an existing Conversation.
     * @param {string} conversationId ID of the conversation to rename.
     * @param {string} newName New name for the conversation.
     */
    const onRenameConversation = async (
        conversationId: number,
        newName: string
    ) => {
        if (user === null) return
        // Guard clause to prevent renaming a conversation to an empty string
        if (newName.trim() === '') newName = 'New Chat'
        // Create a copy of the current states to revert to in case of an error
        const originalMessages: MessagesState = JSON.parse(
            JSON.stringify(messages)
        )
        const originalConversations: ConversationsState = JSON.parse(
            JSON.stringify(conversations)
        )
        const originalSelectedConversation: Conversation | null = JSON.parse(
            JSON.stringify(selectedConversation)
        )
        const original_name = conversations.conversations.find(
            (c) => c.id === conversationId
        )?.name
        try {
            // Update the conversation name in the local state
            setConversations({
                conversations: conversations.conversations.map((c) => {
                    if (c.id === conversationId) {
                        return { ...c, name: newName }
                    }
                    return c
                }),
            })
            // Change selected conversation name if it's the one being renamed
            if (selectedConversation?.id === conversationId) {
                setSelectedConversation({
                    ...selectedConversation,
                    name: newName,
                })
            }
            // API call to rename the conversation
            const conversationResponse = await fetch(
                `${API_BASE_URL}/conversation/rename`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + user.accessToken,
                    },
                    body: JSON.stringify({
                        name: newName,
                        id: conversationId,
                    }),
                }
            )

            // Parse the response JSON and update the conversation object
            const updatedConversationDataJson =
                await conversationResponse.json()
            const updatedConversationData: Conversation = {
                id: updatedConversationDataJson.id,
                userId: updatedConversationDataJson.user_id,
                name: updatedConversationDataJson.name,
                createdAt: new Date(updatedConversationDataJson.created_at),
                updatedAt: new Date(updatedConversationDataJson.updated_at),
                lastViewedMessageId:
                    updatedConversationDataJson.last_viewed_message_id,
            }
            let newConversations = { ...conversations }
            newConversations.conversations =
                newConversations.conversations.filter(
                    (conversation) =>
                        conversation.id !== updatedConversationData.id
                )
            newConversations.conversations.unshift(updatedConversationData)
            setConversations(newConversations)
            if (selectedConversation?.id === conversationId) {
                setSelectedConversation({
                    ...selectedConversation,
                    name: newName,
                })
            }
        } catch (error) {
            console.error('Error renaming conversation:', error)
            setConversations(originalConversations)
            setSelectedConversation(originalSelectedConversation)
            setMessages(originalMessages)
            setEditingConversationId(null)
            try {
                await fetch(`${API_BASE_URL}/conversation/rename`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + user.accessToken,
                    },
                    body: JSON.stringify({
                        name: original_name,
                        id: conversationId,
                    }),
                })
            } catch (error) {
                console.error('Error resetting conversation name:', error)
            }
        }
    }

    /**
     * Prepares to delete a conversation by setting it as the conversation to delete and opening the delete modal.
     * @param {string} conversationId ID of the conversation to delete.
     */
    const onDeleteConversation = (conversationId: number) => {
        if (conversations.conversations.length === 1) {
            return
        }
        setConversationToDelete(conversationId)
        setIsDeleteModalOpen(true)
    }

    /**
     * Closes the delete confirmation modal.
     */
    const handleCloseModal = () => {
        setIsDeleteModalOpen(false)
    }

    /**
     * Deletes the specified conversation and updates the conversation list and messages accordingly.
     */
    const handleConfirmDelete = () => {
        if (!user) {
            throw new Error('User not logged in')
        }
        if (conversationToDelete === null) return
        const newConversations = { ...conversations }
        let newSelectedConversation = selectedConversation
        newConversations.conversations = newConversations.conversations.filter(
            (conversation) => conversation.id !== conversationToDelete
        )
        setConversations(newConversations)
        if (conversationToDelete === selectedConversation!.id) {
            newSelectedConversation = newConversations.conversations[0]
            setSelectedConversation(selectedConversation)
        }
        try {
            fetch(
                `${API_BASE_URL}/conversation/delete/${conversationToDelete}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + user.accessToken,
                    },
                }
            )
            setConversationToDelete(null)
            setIsDeleteModalOpen(false)
            setSelectedConversation(newSelectedConversation)
        } catch (error) {
            console.error('Error deleting conversation:', error)
        }
        setIsDeleteModalOpen(false)
        setConversationToDelete(null)
    }

    return {
        isDeleteModalOpen,
        conversationToDelete,
        editingConversationId,
        setEditingConversationId,
        onCreateNewConversation,
        onRenameConversation,
        onDeleteConversation,
        handleCloseModal,
        handleConfirmDelete,
    }
}

export default useConversations
