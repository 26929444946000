import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import React from 'react'
import Main from "./Main";
import Login from "./Login";


function Container() {
    const { isLoading, isAuthenticated } = useAuth0();

    return (<Routes>
        <Route
            path='/'
            element={
                (isLoading || isAuthenticated ?
                    <Main /> : <Navigate to="/login" />)}
        />
        <Route
            path='/login'
            element={<Login />}
        />
    </Routes>)
}

export default Container