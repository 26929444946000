import React from 'react'

interface ModalProps {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
    children: React.ReactNode
    confirmColor?: string
    confirmColorHover?: string
    onReset?: () => void
    resetColor? : string
}

const Modal: React.FC<ModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    onReset,
    children,
    confirmColor,
    confirmColorHover,
    resetColor
}) => {
    if (!isOpen) return null

    return (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
            <div className='bg-slate-200 p-6 rounded shadow-lg'>
                <div className='mb-4 text-center'>{children}</div>
                <div className='flex justify-center'>
                {onReset && resetColor && (
                <button
                        onClick={onReset}
                        className={`${resetColor} ${confirmColorHover} text-white font-bold py-2 px-4 rounded mr-2`}
                    >
                        Reset to Default
                    </button>
                )}
                    <button
                        onClick={onConfirm}
                        className={`${confirmColor} ${confirmColorHover} text-white font-bold py-2 px-4 rounded mr-2`}
                    >
                        Confirm
                    </button>
                    <button
                        onClick={onClose}
                        className='bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded'
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Modal
