import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { User, UserContextType } from '../types';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserData } from '../api/userAPI';



const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const {isAuthenticated, user:auth0_user, isLoading, getAccessTokenSilently, logout, getIdTokenClaims} = useAuth0();
  const [user, setUser] = useState<User | null>(null);

  useEffect( ()=>{
    const fetchUser = async () =>{
      if (!isLoading){
        if (!isAuthenticated){
          logoutUser();
        } else{
          const token = await getAccessTokenSilently()
          const claims = await getIdTokenClaims();
          if (!claims?.email || !claims?.sub){
            return;
          }
          console.log("GETTING USER DATA", claims)
          const userData = await getUserData(token, claims?.email, claims?.sub)
          console.log("DATA", userData)
          loginUser(userData, token)
        }
      } 
    }

    if (user?.email === auth0_user?.email){
      return;
    } else{
      fetchUser(); // Cannot use async await calls directly in useEffect
    }

  }, [isAuthenticated, isLoading, auth0_user])

  const loginUser = (userData: User, token: string) => {
    console.log("SETTING USER")
    setUser({...userData, accessToken: token});
    console.log(user)
  };

  const logoutUser = () => {
    logout({logoutParams : {returnTo : `${window.location.origin}/login`}});
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, loginUser, logoutUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

